import { useRef, useState, useMemo } from 'react';
import PropTypes from 'prop-types';

import FilesPreview from 'common/components/thumbnail/FilesPreview';
import { baseURL } from 'services/axiosBaseInstance';
import { AttachmentPath } from 'services/attachments';
import { useScreenCaptures } from 'remote-state/useScreenCapturesHooks';
import { StyledAttachments, StyledEditorAttachments } from './style';
import { MoreAttachmentsWrapper } from './MoreAttachmentsWrapper';

export const Attachments = (props) => {
  const {
    attachments,
    isReadOnly,
    isDeleted,
    isDownloaded,
    shouldTriggerDeleteApi,
    onAttachmentDeleted,
    deleteAttachmentEnabled,
    srPanelCollapsed,
    isMinimizedSize,
  } = props;
  const [selectedFileIndex, setSelectedImageIndex] = useState(0);
  const [displayPreview, setDisplayPreview] = useState(false);
  const { screenCaptures = [] } = useScreenCaptures();

  const wrapperRef = useRef(null);

  const handleDisplayPreview = (index) => {
    setSelectedImageIndex(index);
    setDisplayPreview(true);
  };

  const handleImageRemove = () => {
    setDisplayPreview(false);
  };

  const filesData = useMemo(() => {
    const filesData = [];

    attachments?.forEach((attachment) => {
      const fileUrl = `${baseURL}${AttachmentPath.V3}${attachment.id}/payload?inline=true`;
      const fileData = {
        key: attachment.id,
        attachmentId: attachment.id,
        fileId: attachment.id,
        subResourceType: attachment.subResourceType,
        src: fileUrl,
        fileName: attachment.originalName,
        extension: attachment.extension,
        removeParams: { fileUrl },
      };

      filesData.push(fileData);
    });

    screenCaptures?.forEach((screenCapture) => {
      filesData.push({
        ...screenCapture,
        src: screenCapture.fileUrl,
        key: screenCapture.fileName,
        attachmentId: screenCapture.fileName,
        screenCaptureType: screenCapture.type,
        isScreenCapture: true,
      });
    });

    return filesData;
  }, [attachments, screenCaptures]);

  return !isReadOnly ? (
    // Attachment Description field + Attachment Note
    <StyledEditorAttachments isMinimizedSize={isMinimizedSize}>
      <MoreAttachmentsWrapper
        attachments={attachments}
        wrapperRef={wrapperRef}
        isReadOnly={isReadOnly}
        isDeleted={isDeleted}
        isDownloaded={isDownloaded}
        shouldTriggerDeleteApi={shouldTriggerDeleteApi}
        onAttachmentDeleted={onAttachmentDeleted}
        deleteAttachmentEnabled={deleteAttachmentEnabled}
        noButton="true"
        srPanelCollapsed={srPanelCollapsed}
        handleDisplayPreview={handleDisplayPreview}
        isMinimizedSize={isMinimizedSize}
      />
      {displayPreview && (
        <FilesPreview
          filesData={filesData}
          // filesData={attachments}
          selectedFileIndex={selectedFileIndex}
          onClose={() => setDisplayPreview(false)}
          onFileRemove={handleImageRemove}
        />
      )}
    </StyledEditorAttachments>
  ) : (
    // Journey
    <StyledAttachments ref={wrapperRef}>
      <MoreAttachmentsWrapper
        isReadOnly={isReadOnly}
        wrapperRef={wrapperRef}
        attachments={attachments}
        isDeleted={isDeleted}
        isDownloaded={isDownloaded}
        shouldTriggerDeleteApi={shouldTriggerDeleteApi}
        onAttachmentDeleted={onAttachmentDeleted}
        deleteAttachmentEnabled={deleteAttachmentEnabled}
        srPanelCollapsed={srPanelCollapsed}
        handleDisplayPreview={handleDisplayPreview}
        filesData={filesData}
      />
      {displayPreview && (
        <FilesPreview
          filesData={filesData}
          selectedFileIndex={selectedFileIndex}
          onClose={() => setDisplayPreview(false)}
          onFileRemove={handleImageRemove}
        />
      )}
    </StyledAttachments>
  );
};

Attachments.propTypes = {
  attachments: PropTypes.array,
  isReadOnly: PropTypes.bool,
  isDeleted: PropTypes.bool,
  isDownloaded: PropTypes.bool,
  onAttachmentDeleted: PropTypes.func,
  isMinimizedSize: PropTypes.bool,
};

Attachments.defaultProps = {
  attachments: [],
  onAttachmentDeleted: () => {},
  isReadOnly: true,
  isDeleted: false,
  isDownloaded: false,
  isMinimizedSize: false,
};
